import { none } from 'fp-ts/lib/Option'
import React from 'react'

import NavDropdown from './NavDropdown'
import NavLink from './NavLink'
import type { MainMenuProps, NavLinkProps, SimpleMenuProps } from './schema'

/*
We have a copy of this Component in shared/components/src/v2/lib as well which
supports data fetched from apollo instead of gatsby data layer. Functionality and
structure is exactly same in both the components, so if you are making any changes
to this, please make corresponding changes to the v2 version of this component too.
We will make v2/ version the main version going forward when we are completely swapped
over to apollo from gatsby data layer.
*/

const isMenu = (el: NavLinkProps | SimpleMenuProps): el is SimpleMenuProps =>
  el.__typename === 'ContentfulMenu'

export default function DesktopNavMenu({
  menu
}: {
  readonly menu: MainMenuProps
}) {
  const { links } = menu

  return (
    <nav
      aria-label="Links bar"
      className="hidden h-full items-center justify-between gap-6 justify-self-start lg:flex"
    >
      {links.map((element, index) =>
        !isMenu(element) ? (
          <NavLink
            className="hover:text-primary-100 text-white"
            key={`Desktop-nav-link-${index}`}
            trackingLabel={none}
            {...element}
          />
        ) : (
          <NavDropdown key={`Desktop-nav-dropdown-${index}`} {...element} />
        )
      )}
    </nav>
  )
}
