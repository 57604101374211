import { iconTypes } from '@ecomm/shared-icons'
import { TypeOf, z } from '@simplisafe/ewok'

import { contentfulRichTextSchema } from '../ContentfulRichText/contentfulRichTextSchema'
import { apolloRichTextSchema } from '@ecomm/contentful-schemas'

const sysSchema = z.object({
  id: z.string()
})

export const iconAndTextSchema = z.object({
  __typename: z.literal('ContentfulIconAndText'),
  className: z.string().optional(),
  contentful_id: z.string(),
  disclaimer: z.string().nullish(),
  icon: z.enum(iconTypes),
  text: contentfulRichTextSchema,
  title: z.string()
})

export const apolloIconAndTextSchema = z.object({
  __typename: z.literal('IconAndText'),
  className: z.string().optional(),
  sys: sysSchema,
  id: sysSchema.transform(({ id }) => id),
  disclaimer: z.string().nullish(),
  icon: z.enum(iconTypes),
  text: apolloRichTextSchema,
  title: z.string()
})

export type IconAndTextSchema = TypeOf<typeof iconAndTextSchema>
export type ApolloIconAndTextSchema = TypeOf<typeof apolloIconAndTextSchema>
