import { baseCartsUrl } from '@ecomm/data-env-variables'

import { fetchApi } from './fetchApi'

/**
 * Create a cart fetch request
 */
export const cartFetch = (
  method: 'GET' | 'POST',
  endpoint: string,
  body?: string,
  variationId?: string
) =>
  fetchApi({
    method,
    body,
    headers: {
      ...(variationId && { 'OPT-VARIATION-IDS': variationId })
    },
    baseUrlOverride: baseCartsUrl(),
    endpoint: endpoint
  })
