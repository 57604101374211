import { gql } from '@apollo/client/index.js'

export const QUOTE_WIZARD_QUERY = gql`
fragment QWBase on QuoteWizard {
  __typename
  sys {
    id
  }
  id: sys {
      id
    }
  jebbitUrl
  type
  buttonText
}

fragment QWExperience on NtExperience {
  sys {
    id
  }
  id: sys {
      id
    }
  name: ntName
  type: ntType
  audience: ntAudience {
    sys {
      id
    }
    name: ntName
  }
  config: ntConfig
  variants: ntVariantsCollection(limit: 40) {
    items {
      ... on QuoteWizard {
        ...QWBase
      }
    }
  }
}

query QuoteWizard($id : String! $preview : Boolean! $locale : String!) {
  quoteWizard(id: $id, preview : $preview, locale : $locale) {
    ...QWBase
    ntExperiencesCollection {
      items {
        ...QWExperience
      }
    }
  }
}
`
