import { iconTypes, type IconTypes } from '@ecomm/shared-icons'
import { z } from '@simplisafe/ewok'

type InfoIcon = {
  readonly description: string | readonly string[]
  readonly modalLocation:
    | 'battery-backup'
    | 'fast-protect'
    | 'home-integration'
    | 'home-protection'
    | 'police-response'
  readonly title: string
}

type BaseComparisonTableRow = {
  readonly feature: string
  readonly infoIcon?: InfoIcon
}

type ComparisonTableRowWithValues = BaseComparisonTableRow & {
  readonly values: readonly (boolean | string)[]
  readonly ratings?: never
}

type ComparisonTableRowWithRating = BaseComparisonTableRow & {
  readonly ratings: readonly number[]
  readonly values?: never
}

export type ComparisonTableRow =
  | ComparisonTableRowWithRating
  | ComparisonTableRowWithValues

export type ComparisonTableRowsGroup = {
  readonly groupName: string
  readonly groupIcon: IconTypes
  readonly rows: readonly ComparisonTableRow[]
}

export type ComparisonTableProps = {
  readonly header: {
    readonly competitors: readonly string[]
    readonly titles: readonly string[]
    readonly icons?: readonly IconTypes[]
  }
  readonly body: readonly (ComparisonTableRow | ComparisonTableRowsGroup)[]
  readonly footer: React.ReactNode
}

/**
 * TODO: This is all generated by co-pilot and is most likely not fully accurate.
 * TODO: ECP-12911
 */
const InfoIconSchema = z.object({
  description: z.union([z.string(), z.array(z.string())]),
  modalLocation: z.enum([
    'battery-backup',
    'fast-protect',
    'home-integration',
    'home-protection',
    'police-response'
  ]),
  title: z.string()
})

const BaseComparisonTableRowSchema = z.object({
  feature: z.string(),
  infoIcon: InfoIconSchema.optional()
})

const ComparisonTableRowWithValuesSchema = BaseComparisonTableRowSchema.extend({
  values: z.array(z.union([z.boolean(), z.string()])),
  ratings: z.never().optional()
}).passthrough() // TODO: this passthrough is a hack and needs to be fixed

const ComparisonTableRowWithRatingSchema = BaseComparisonTableRowSchema.extend({
  ratings: z.array(z.number()),
  values: z.never().optional()
})

const ComparisonTableRowSchema = z.union([
  ComparisonTableRowWithValuesSchema,
  ComparisonTableRowWithRatingSchema
])

const ComparisonTableRowsGroupSchema = z
  .object({
    groupName: z.string(),
    groupIcon: z.enum(iconTypes),
    rows: z.array(ComparisonTableRowSchema)
  })
  .passthrough() // TODO: this passthrough is a hack and needs to be fixed

/**
 * TODO: This is all generated by co-pilot and is most likely not fully accurate.
 * Please fix this ASAP.
 * TODO: ECP-12911
 */
export const ComparisonTablePropsSchema = z.object({
  header: z.object({
    competitors: z.array(z.string()),
    titles: z.array(z.string()),
    icons: z.array(z.enum(iconTypes)).optional()
  }),
  body: z.array(
    z.union([ComparisonTableRowSchema, ComparisonTableRowsGroupSchema])
  ),
  footer: z.array(z.string())
})
