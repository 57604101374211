import classNames from 'classnames'
import React from 'react'

/*
We have a copy of this Component in shared/components/src/v2/lib as well which
supports data fetched from apollo instead of gatsby data layer. Functionality and
structure is exactly same in both the components, so if you are making any changes
to this, please make corresponding changes to the v2 version of this component too.
We will make v2/ version the main version going forward when we are completely swapped
over to apollo from gatsby data layer.
*/

type ProgressBarProps = {
  readonly className?: string
  readonly steps: readonly string[]
  readonly progress: number
}

export default function ProgressBar({
  className = '',
  steps,
  progress
}: ProgressBarProps) {
  return (
    <div
      className={classNames(
        'flex w-full max-w-[500px] flex-col text-xs font-light md:text-sm',
        className
      )}
      data-component={ProgressBar.name}
      role="progressbar"
    >
      <div className="rounded-base bg-neutral-light-50 h-3 w-full">
        <div
          className="rounded-base bg-primary-100 h-full"
          data-component="ProgressBarLine"
          style={{ width: `${progress}%` }}
        />
      </div>
      <ul className="m-0 mx-0 mb-0 flex list-none items-center justify-between gap-2 p-0 md:mt-1">
        {steps.map(title => (
          <li className="text-white" key={title}>
            {title}
          </li>
        ))}
      </ul>
    </div>
  )
}
