import { trackNavigationClick } from '@ecomm/cdp-tracking'
import classNames from 'classnames'
import { type Option, match } from 'fp-ts/lib/Option'
import { Link } from '@ecomm/framework'
import React from 'react'
import { useTracking } from 'react-tracking'

import { GatsbyImage } from '../GatsbyImage'
import type { NavLinkProps } from './schema'

/*
We have a copy of this Component in shared/components/src/v2/lib as well which
supports data fetched from apollo instead of gatsby data layer. Functionality and
structure is exactly same in both the components, so if you are making any changes
to this, please make corresponding changes to the v2 version of this component too.
We will make v2/ version the main version going forward when we are completely swapped
over to apollo from gatsby data layer.
*/

export default function NavLink({
  className = '',
  text,
  url,
  hideIcon = false,
  icon,
  trackingLabel
}: NavLinkProps & {
  readonly className?: string
  readonly hideIcon?: boolean
  readonly trackingLabel: Option<string>
}) {
  const isExternal = /(http|https):\/\/.*/.test(url)

  const { trackEvent } = useTracking()

  const trackAnEvent = () => {
    const label = match(
      () => text,
      (trackingLabel: string) => trackingLabel
    )(trackingLabel)
    trackEvent({
      label: label,
      event: 'navigation-link',
      eventAction: 'click',
      eventCategory: 'navigation'
    })
    trackNavigationClick({
      action: 'click',
      navElement: label
    })
  }

  const css = classNames(
    'inline-flex items-center gap-4 no-underline',
    className
  )

  const iconElement = icon && !hideIcon && (
    <GatsbyImage
      className="h-full w-full lg:h-20 lg:w-20"
      image={icon}
      objectFit="contain"
    />
  )

  return !isExternal ? (
    <Link className={css} onClick={trackAnEvent} to={url}>
      {iconElement}
      {text}
    </Link>
  ) : (
    <a className={css} href={url} onClick={trackAnEvent}>
      {iconElement}
      {text}
    </a>
  )
}
