import type { GatsbyImageSchema } from '@ecomm/contentful-schemas'
import equals from 'fast-deep-equal'
import {
  getImage,
  type GatsbyImageProps,
  GatsbyImage as GImage
} from 'gatsby-plugin-image'
import React, { useCallback, useMemo, useState } from 'react'

type Props = Omit<GatsbyImageProps, 'alt' | 'image'> & {
  readonly image: GatsbyImageSchema
}

/**
 * @deprecated Use `ContentfulImage` from `@ecomm/contentful/components` instead.
 * The performance for `GatsbyImage` is terrible in comparison to native HTML `img` elements with proper lazy loading.
 */
function InternalGatsbyImage({ image, ...rest }: Props) {
  const imageProp = useMemo(() => getImage(image), [image])
  const [isLoaded, setLoaded] = useState(false)
  const handleStartLoad = useCallback(() => {
    setLoaded(true)
  }, [])

  const imgStyle = useMemo(() => {
    return {
      ...rest.imgStyle,
      ...(isLoaded ? { opacity: 1 } : {})
    }
  }, [isLoaded])

  return imageProp ? (
    <GImage
      alt={image?.description || image?.title || ''}
      decoding="sync"
      image={imageProp}
      imgStyle={imgStyle}
      loading="eager"
      onStartLoad={handleStartLoad}
      {...rest}
    />
  ) : null
}

/**
 * @deprecated Use `ContentfulImage` from `@ecomm/contentful/components` instead.
 * The performance for `GatsbyImage` is terrible in comparison to native HTML `img` elements with proper lazy loading.
 */
export const GatsbyImage = React.memo(InternalGatsbyImage, (prev, next) => {
  return equals(prev, next)
})
export type { GatsbyImageSchema }
