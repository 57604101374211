import { toZonedTime } from 'date-fns-tz'
import { useEffect, useState } from 'react'

function getAreExpertsAvailable(from: number, to: number) {
  const zonedDate = toZonedTime(new Date(), 'America/New_York')
  return zonedDate.getHours() >= from && zonedDate.getHours() < to
}

/**
 * Returns whether experts are currently available via phone and chat according to a fixed schedule
 * (default: every day from 8:00am - Midnight ET). Updates every second.
 */
export function useAreExpertsAvailable(from = 8, to = 24) {
  const [areExpertsAvailable, setAreExpertsAvailable] = useState(
    getAreExpertsAvailable(from, to)
  )
  useEffect(() => {
    const interval = setInterval(() => {
      setAreExpertsAvailable(getAreExpertsAvailable(from, to))
    }, 1000)
    return () => clearInterval(interval)
  }, [from, setAreExpertsAvailable, to])

  return areExpertsAvailable
}
