import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import { type TypeOf, z } from '@simplisafe/ewok'

import { quoteWizardSchema } from '../../data'
const imageLinkSchema = z.object({
  __typename: z.literal('ContentfulImageLink'),
  image: gatsbyImageSchema.optional(),
  title: z.string().default(''),
  url: z.string().default(''),
  label: z.string().optional()
})

const headerTypes = z.enum(['full', 'slim', 'lander', 'pla'])

export const navLinkSchema = z.object({
  __typename: z.literal('ContentfulLink'),
  title: z.string(),
  icon: gatsbyImageSchema.nullable(),
  text: z.string(),
  url: z.string()
})

//Menu inside main header structure that only contains links and not other menues
export const simpleMenuSchema = z.object({
  __typename: z.literal('ContentfulMenu'),
  links: z.array(navLinkSchema),
  title: z.string()
})

export const mainMenuSchema = z.object({
  __typename: z.literal('ContentfulMenu'),
  links: z.array(z.union([simpleMenuSchema, navLinkSchema]))
})

export const gridSchema = z.object({
  __typename: z.literal('ContentfulGrid'),
  gridItems: navLinkSchema.array()
})

export const mobileMenuSchema = z
  .union([mainMenuSchema, gridSchema, imageLinkSchema])
  .array()

const ctaMenuSchema = z.object({
  phoneNumber: z.string().optional(),
  cartQuantity: z.number().optional(),
  showShopCTA: z.boolean().optional(),
  headerType: headerTypes.optional(),
  quoteWizard: quoteWizardSchema.nullish().optional()
})

export const headerSchema = z.object({
  __typename: z.literal('ContentfulHeader'),
  contentful_id: z.string(),
  desktopMenu: mainMenuSchema.nullish(),
  mobileMenu: mobileMenuSchema.nullish(),
  type: headerTypes.nullish().transform(type => type ?? 'slim'),
  quoteWizard: quoteWizardSchema.nullish(), //To be moved to the QuoteWizard component
  progressBarProps: z
    .object({
      progress: z.number(),
      steps: z.string().array()
    })
    .nullish(),
  ctaMenu: ctaMenuSchema.optional()
})

export type HeaderProps = TypeOf<typeof headerSchema>
export type MainMenuProps = TypeOf<typeof mainMenuSchema>
export type NavLinkProps = TypeOf<typeof navLinkSchema>
export type SimpleMenuProps = TypeOf<typeof simpleMenuSchema>
export type MobileMenuProps = TypeOf<typeof mobileMenuSchema>
export type GridProps = TypeOf<typeof gridSchema>
export type CTAMenuProps = TypeOf<typeof ctaMenuSchema>
export type ImageLinkSchemaProps = TypeOf<typeof imageLinkSchema>
export type HeaderType = TypeOf<typeof headerTypes>
