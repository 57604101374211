import { trackNavigationClick } from '@ecomm/cdp-tracking'
import { useOnClickOutside } from '@ecomm/shared-hooks'
import { Profile } from '@ecomm/shared-icons'
import classNames from 'classnames'
import { Link } from '@ecomm/framework'
import React, { type HTMLAttributes, useRef, useState } from 'react'
import { useTracking } from 'react-tracking'

/*
We have a copy of this Component in shared/components/src/v2/lib as well which
supports data fetched from apollo instead of gatsby data layer. Functionality and
structure is exactly same in both the components, so if you are making any changes
to this, please make corresponding changes to the v2 version of this component too.
We will make v2/ version the main version going forward when we are completely swapped
over to apollo from gatsby data layer.
*/

type IconDropdownProps = HTMLAttributes<HTMLMenuElement> & {
  readonly links: readonly { readonly url: string; readonly label: string }[]
}
export default function IconDropdown({
  links = [],
  className,
  ...rest
}: IconDropdownProps) {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  const onClickOutside = () => {
    setIsOpen(false)
  }

  const { trackEvent } = useTracking()
  const trackClick = (label: string) => {
    trackEvent({
      label,
      event: 'navigation-link',
      eventAction: 'click',
      eventCategory: 'navigation'
    })
    trackNavigationClick({
      action: 'click',
      navElement: label
    })
  }

  const isExternal = (url: string) => /(http|https):\/\/.*/.test(url)

  useOnClickOutside(onClickOutside, ref)

  return (
    <menu
      data-component={IconDropdown.name}
      {...rest}
      className={classNames(
        'group relative h-full cursor-pointer p-0 text-white',
        className
      )}
      ref={ref}
    >
      <button
        className="my-auto h-6 w-6 border-0 bg-transparent p-0 text-white"
        onClick={handleClick}
      >
        <span className="sr-only">Profile</span>
        <Profile className="hover:text-primary-100 mx-auto my-auto h-6 w-6 cursor-pointer" />
      </button>
      <div
        className={classNames(
          "prose-a:text-neutral-black prose-a:no-underline absolute left-1/2 top-10 w-max -translate-x-1/2 bg-white shadow-md before:absolute before:-top-2 before:left-1/2 before:z-[0] before:block before:h-6 before:w-6 before:-translate-x-1/2 before:rotate-45 before:transform before:bg-white before:content-[''] group-hover:block md:top-20",
          {
            block: isOpen,
            hidden: !isOpen
          }
        )}
        data-component="IconDropdownNav"
      >
        <ul className="relative z-[1] m-0 list-none p-0">
          {links.map((link, index) => (
            <li
              className="hover:bg-neutral-light-100 prose-a:!text-neutral-black hover:prose-a:!text-primary-100 cursor-pointer px-6 py-3"
              key={`icon-dropdown-${index}`}
            >
              {isExternal(link.url) ? (
                <a href={link.url} onClick={() => trackClick(link.label)}>
                  {link.label}
                </a>
              ) : (
                <Link onClick={() => trackClick(link.label)} to={link.url}>
                  {link.label}
                </Link>
              )}
            </li>
          ))}
        </ul>
      </div>
    </menu>
  )
}
