import { trackNavigationClick } from '@ecomm/cdp-tracking'
import classNames from 'classnames'
import { match, type Option } from 'fp-ts/lib/Option'
import React from 'react'
import { useTracking } from 'react-tracking'

import type { NavLinkProps } from './schema'
import { ContentfulImage } from '@ecomm/contentful/components'
import { Link } from '@ecomm/framework'

export default function NavLink({
  className = '',
  text,
  url,
  hideIcon = false,
  icon,
  trackingLabel
}: NavLinkProps & {
  readonly className?: string
  readonly hideIcon?: boolean
  readonly trackingLabel: Option<string>
}) {
  const isExternal = /(http|https):\/\/.*/.test(url)

  const { trackEvent } = useTracking()

  const trackAnEvent = () => {
    const label = match(
      () => text,
      (trackingLabel: string) => trackingLabel
    )(trackingLabel)
    trackEvent({
      label: label,
      event: 'navigation-link',
      eventAction: 'click',
      eventCategory: 'navigation'
    })
    trackNavigationClick({
      action: 'click',
      navElement: label
    })
  }

  const css = classNames(
    'inline-flex items-center gap-4 no-underline',
    className
  )

  const iconElement = icon && !hideIcon && (
    <ContentfulImage {...icon} className="h-full w-full lg:h-20 lg:w-20" />
  )

  return !isExternal ? (
    <Link className={css} onClick={trackAnEvent} to={url}>
      {iconElement}
      {text}
    </Link>
  ) : (
    <a className={css} href={url} onClick={trackAnEvent}>
      {iconElement}
      {text}
    </a>
  )
}
