import { contentfulImageSchema } from '@ecomm/contentful/components'
import { type TypeOf, z } from '@simplisafe/ewok'
import { guidedSystemBuilderSchema } from '../GuidedSystemBuilder/schema'

export const navLinkSchema = z.object({
  __typename: z.literal('Link'),
  sys: z.object({ id: z.string() }),
  internalTitle: z.string(),
  icon: contentfulImageSchema
    .extend({
      sys: z.object({ id: z.string() })
    })
    .nullable(),
  text: z.string(),
  url: z.string()
})

export const simpleMenuSchema = z.object({
  __typename: z.literal('Menu'),
  sys: z.object({ id: z.string() }),
  links: z.object({ items: z.array(navLinkSchema) }),
  title: z.string()
})

export const mainMenuSchema = z.object({
  __typename: z.literal('Menu'),
  links: z.object({
    items: z.array(z.union([simpleMenuSchema, navLinkSchema]))
  })
})

const imageLinkSchema = z.object({
  __typename: z.literal('ImageLink'),
  image: contentfulImageSchema.optional(),
  title: z.string().default(''),
  url: z.string().default('')
})

export const gridSchema = z.object({
  __typename: z.literal('Grid'),
  gridItemsCollection: z.object({ items: navLinkSchema.array() })
})

export const mobileMenuSchema = z.object({
  items: z.union([mainMenuSchema, gridSchema, imageLinkSchema]).array()
})

const headerTypes = z.enum(['full', 'slim', 'lander', 'pla'])

const ctaMenuSchema = z.object({
  phoneNumber: z.string().optional(),
  cartQuantity: z.number().optional(),
  showShopCTA: z.boolean().optional(),
  headerType: headerTypes.optional(),
  quoteWizard: guidedSystemBuilderSchema.nullish().optional()
})

export const headerSchema = z.object({
  __typename: z.literal('Header'),
  sys: z.object({ id: z.string() }),
  desktopMenu: mainMenuSchema.nullish(),
  mobileMenuCollection: mobileMenuSchema.nullish(),
  type: headerTypes.nullish().transform(type => type ?? 'slim'),
  quoteWizard: guidedSystemBuilderSchema.nullish(),
  progressBarProps: z
    .object({
      progress: z.number(),
      steps: z.string().array()
    })
    .nullish(),
  ctaMenu: ctaMenuSchema.optional()
})

export type HeaderProps = TypeOf<typeof headerSchema>
export type MainMenuProps = TypeOf<typeof mainMenuSchema>
export type NavLinkProps = TypeOf<typeof navLinkSchema>
export type SimpleMenuProps = TypeOf<typeof simpleMenuSchema>
export type MobileMenuProps = TypeOf<typeof mobileMenuSchema>
export type GridProps = TypeOf<typeof gridSchema>
export type CTAMenuProps = TypeOf<typeof ctaMenuSchema>
export type ImageLinkSchemaProps = TypeOf<typeof imageLinkSchema>
export type HeaderType = TypeOf<typeof headerTypes>
