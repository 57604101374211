import { devError } from '@ecomm/error-handling'
import type { ExperienceConfiguration } from '@ninetailed/experience.js-gatsby'
import { ExperienceMapper, type Variant } from '@ninetailed/experience.js-utils'

/**
 * @deprecated Use `safeMapExperiences` instead!
 *
 * SERIOUSLY DO NOT USE THIS!
 *
 * This is based on examples provided by Ninetailed, but the new function will fail gracefully and has stronger type checking.
 *
 * ECP-12916
 */
export const mapExperiences = <T extends Variant>(
  experienceData: any
): ExperienceConfiguration<T>[] => {
  devError('DO NOT USE mapExperiences! Use safeMapExperiences instead!')
  return (experienceData || [])
    .filter(ExperienceMapper.isExperienceEntry)
    .map(ExperienceMapper.mapExperience)
}

/**
 * @deprecated Use `safeMapExperiences` instead!
 *
 * SERIOUSLY DO NOT USE THIS!
 *
 * This is based on examples provided by Ninetailed, but the new function will fail gracefully and has stronger type checking.
 *
 * ECP-12916
 */
export const mapApolloExperiences = <T extends Variant>(
  experienceData: any
): ExperienceConfiguration<T>[] => {
  devError('DO NOT USE mapExperiences! Use safeMapExperiences instead!')
  return (experienceData || [])
    .map((experience: any) => {
      return {
        ...experience,
        id: experience.id.id,
        audience: {
          ...experience.audience,
          id: experience.audience.id.id
        },
        variants: experience.variants.items.map((variant: any) => {
          return {
            ...variant,
            id: variant.id
          }
        })
      }
    })
    .filter(ExperienceMapper.isExperienceEntry)
    .map(ExperienceMapper.mapExperience)
}
