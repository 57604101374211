import './fragments/contentfulProduct'

import { listenToJebbitPostMessages } from '@ecomm/cdp-tracking'
import { productsAtom, productTopicSchema } from '@ecomm/data-products'
import { cookieAtom } from '@ecomm/data-storage'
import { ErrorBoundary } from '@ecomm/error-handling'
import {
  type GqlEvergreen,
  type GqlPackage,
  type GqlProduct,
  parseEvergreen,
  parsePackages,
  parseProducts,
  parseProductsV2
} from '@ecomm/gatsby-plugins/utils'
import {
  createExperiencesDictionary,
  ExperiencesContext,
  InjectContext
} from '@ecomm/gatsby-wrappers'
import { optimizelyClientAtom } from '@ecomm/optimizely-utils'
import {
  initBraze,
  OptimizelyProvider,
  useTrackingVisitorArrival
} from '@ecomm/tracking'
import { createStore } from '@simplisafe/ss-ecomm-data/redux/store'
import { document } from 'browser-monads-ts'
import * as O from 'fp-ts/lib/Option'
import { graphql, Script, ScriptStrategy, useStaticQuery } from 'gatsby'
import { useHydrateAtoms } from 'jotai/utils'
import { type ReactElement, useEffect, useMemo } from 'react'
import { CookiesProvider } from 'react-cookie'
import { Helmet } from 'react-helmet'
import { Provider } from 'react-redux'
import { QueryParamProvider } from 'use-query-params'
import { ReachAdapter } from 'use-query-params/adapters/reach'

import { DevTools } from './DevTools'
import { HideOneTrustBanner } from './HideOneTrustBanner'

type Data = {
  readonly evergreen: GqlEvergreen
  readonly allProduct: {
    readonly edges: readonly { readonly node: GqlProduct }[]
  }
  readonly allPackage: {
    readonly edges: readonly { readonly node: GqlPackage }[]
  }
  readonly allContentfulProduct: {
    readonly edges: readonly { readonly node: unknown }[]
  }
  readonly allContentfulNinetailedExperience: {
    readonly nodes: readonly { readonly id: string; readonly name: string }[]
  }
}

type WrapWithProviderProps = {
  readonly element: ReactElement
}

const PX_ID = process.env['GATSBY_PX_APP_ID']
const affirmKey = process.env['GATSBY_AFFIRM_PUBLIC_KEY']
const locale = process.env['LOCALE']
const oneTrustDomainScript = process.env['ONETRUST_DOMAIN_SCRIPT']
const rudderStackWriteKey = process.env['GATSBY_RUDDERSTACK_WRITE_KEY']
const rudderStackDataPlaneUrl = process.env['GATSBY_RUDDERSTACK_DATAPLANE_URL']

function Wrapper<T extends WrapWithProviderProps>({ element }: T) {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  // Wrapped in useMemo because useStaticQuery (below) can cause rerenders in dev:
  //   see: https://github.com/gatsbyjs/gatsby/issues/29011
  const store = useMemo(() => {
    return createStore()
  }, [])

  const data: Data = useStaticQuery(graphql`
    query RootElement {
      allProduct {
        edges {
          node {
            id
            _xtype
            hardwareVersion
            isCartable
            isIndeterminatePrice
            isOnStock
            isSellable
            isViewable
            masterSku
            name {
              en_GB
              en_US
            }
            nodeId
            price
            productType
            productTypeId
            sku
            restockDate
            variants {
              hardwareVersion
              isCartable
              isIndeterminatePrice
              isOnStock
              isSellable
              isViewable
              name {
                en_GB
                en_US
              }
              price
              quantity
              restockDate
              sku
            }
          }
        }
      }
      evergreen {
        id
        promoBanner {
          backgroundColor
          endTimeCalloutOverride
          displayEndTimeCalloutOverride
          discountSecondaryText
          buttonTextColor
          buttonBackgroundColor
          freeGiftItemPrimaryText
          hasEmailInput
          hasCountdown
          freeGiftItemSecondaryText
          hasEndDateCallout
          hasFreeGiftItem
          hasSaleName
          primaryTextColor
          saleName
          secondaryTextColor
        }
        displayMonitoringDiscount
        endTime
        promoCode
        promoCodeWithMonitoring
        promoFlag {
          backgroundColor
          textColor
        }
        lastFetched
        lastModified
      }
      allPackage {
        edges {
          node {
            _xtype
            absoluteDiscount
            absoluteDiscountWithServicePlan
            discountedPrice
            discountedPriceWithServicePlan
            id
            lastFetched
            price
            products {
              isViewable
              name {
                en_GB
                en_US
              }
              quantity
              sku
            }
            relativeDiscount
            relativeDiscountWithServicePlan
            sku
          }
        }
      }
      allContentfulProduct {
        edges {
          node {
            ...contentfulProduct
          }
        }
      }
      allContentfulNinetailedExperience {
        nodes {
          id: contentful_id
          name: nt_name
        }
      }
    }
  `)

  const products = useMemo(() => {
    return parseProducts(data)
  }, [])

  // Track the initial session visit to Rudderstack. Should only fire
  // once on application lifecycle (at startup).
  useTrackingVisitorArrival()

  //Rudderstack event tracking - Jebbit Post Messages 'quote_wizard_start' and 'quote_wizard_complete'
  useEffect(() => {
    return listenToJebbitPostMessages()
  }, [])

  const combinedProducts = useMemo(() => {
    const contentfulProducts = data.allContentfulProduct.edges.map(t =>
      productTopicSchema.parse(t.node)
    )
    const _commercetoolsProducts = parseProductsV2(data)
    return _commercetoolsProducts.map((value, key) => {
      const item = contentfulProducts.find(x => x.sku === key)
      return { ...value, topic: O.fromNullable(item) }
    })
  }, [])

  const evergreen = useMemo(() => {
    return parseEvergreen(data.evergreen)
  }, [])

  const packages = useMemo(() => {
    return parsePackages(data)
  }, [])

  useHydrateAtoms([
    [optimizelyClientAtom, O.none],
    [productsAtom, combinedProducts],
    [cookieAtom, document.cookie]
  ])

  return (
    <ErrorBoundary>
      {locale === 'en-US' ? <HideOneTrustBanner /> : null}
      <Helmet>
        {/* Loads the base RudderStack SDK, which can latter be used to load the frontend source configuration and dependent SDKs (dependent on consent). */}
        <link
          href={`${process.env['GATSBY_RUDDERSTACK_DATAPLANE_URL']}`}
          rel="preconnect"
        />
        {locale === 'en-US' ? (
          <link href="experience.ninetailed.co" rel="dns-prefetch" />
        ) : null}
        <link
          as="script"
          href={`${process.env['GATSBY_RUDDERSTACK_DATAPLANE_URL']}/v3/modern/rsa.min.js`}
          rel="preload"
        />
        <script id="rudder-stack-sdk" type="text/javascript">
          {`!function(){"use strict";window.RudderSnippetVersion="3.0.3";var sdkBaseUrl="${process.env['GATSBY_RUDDERSTACK_DATAPLANE_URL']}/v3"
  ;var sdkName="rsa.min.js";var asyncScript=true;window.rudderAnalyticsBuildType="legacy",window.rudderanalytics=[]
  ;var e=["setDefaultInstanceKey","load","ready","page","track","identify","alias","group","reset","setAnonymousId","startSession","endSession","consent"]
  ;for(var n=0;n<e.length;n++){var t=e[n];window.rudderanalytics[t]=function(e){return function(){
  window.rudderanalytics.push([e].concat(Array.prototype.slice.call(arguments)))}}(t)}try{
  new Function('return import("")'),window.rudderAnalyticsBuildType="modern"}catch(a){}
  if(window.rudderAnalyticsMount=function(){
  "undefined"==typeof globalThis&&(Object.defineProperty(Object.prototype,"__globalThis_magic__",{get:function get(){
  return this},configurable:true}),__globalThis_magic__.globalThis=__globalThis_magic__,
  delete Object.prototype.__globalThis_magic__);var e=document.createElement("script")
  ;e.src="".concat(sdkBaseUrl,"/").concat(window.rudderAnalyticsBuildType,"/").concat(sdkName),e.async=asyncScript,
  document.head?document.head.appendChild(e):document.body.appendChild(e)
  },"undefined"==typeof Promise||"undefined"==typeof globalThis){var d=document.createElement("script")
  ;d.src="https://polyfill-fastly.io/v3/polyfill.min.js?version=3.111.0&features=Symbol%2CPromise&callback=rudderAnalyticsMount",
  d.async=asyncScript,document.head?document.head.appendChild(d):document.body.appendChild(d)}else{
  window.rudderAnalyticsMount()}}();`}
        </script>
        {oneTrustDomainScript ? (
          <script
            data-domain-script={oneTrustDomainScript}
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            type="text/javascript"
          ></script>
        ) : null}
        {locale === 'en-US' && oneTrustDomainScript ? (
          <script
            id="optanon-configurator"
            rs-data-plane={rudderStackDataPlaneUrl}
            rs-write-key={rudderStackWriteKey}
            src="/ot-rudder-us-20241112.js"
            type="text/javascript"
          />
        ) : null}
        {locale === 'en-GB' && oneTrustDomainScript ? (
          <script
            id="optanon-configurator"
            rs-data-plane={rudderStackDataPlaneUrl}
            rs-write-key={rudderStackWriteKey}
            src="/ot-rudder-uk-20241112.js"
            type="text/javascript"
          />
        ) : null}
      </Helmet>
      <OptimizelyProvider datafile={{}}>
        <QueryParamProvider adapter={ReachAdapter}>
          <DevTools />
          <Provider store={store}>
            <InjectContext
              evergreenPromotion={evergreen}
              packages={packages}
              products={products}
            >
              <CookiesProvider>
                <ExperiencesContext.Provider
                  value={createExperiencesDictionary(
                    data.allContentfulNinetailedExperience.nodes
                  )}
                >
                  {element}
                </ExperiencesContext.Provider>
              </CookiesProvider>
            </InjectContext>
          </Provider>
        </QueryParamProvider>
      </OptimizelyProvider>
      <Script src={`/old-browsers.js`} strategy={ScriptStrategy.idle} />
      <Script id={PX_ID} src={`/perimeterx.js`} />
      {affirmKey ? (
        <Script id={affirmKey} src={`/affirm.js`} strategy="idle" />
      ) : null}
      <Script
        onLoad={() => initBraze()}
        src={`/braze-web-sdk-20230207.js`}
        strategy="idle"
      />
      <Script
        src="https://d2jjzw81hqbuqv.cloudfront.net/integration/clients/simplisafe-staging.min.js"
        strategy="idle"
        type="text/javascript"
      />
    </ErrorBoundary>
  )
}

// Wrap the wrapper in another wrapper to allow the use of React hooks
// https://github.com/gatsbyjs/gatsby/issues/22833#issuecomment-609370401
export function WrapRootElement<T extends WrapWithProviderProps>(t: T) {
  return <Wrapper {...t} />
}
