import { useGoogleAnalytics, usePollVid } from '@ecomm/tracking'
import classNames from 'classnames'
import React, { useRef } from 'react'
import * as pkg from 'react-lazy-load-image-component'

import { embedJebbit } from './jebbitOnLoadScript'
import { createGSBLaunchUrlParams } from './lib'
import type { GuidedSystemBuilderSchema } from './schema'

const { LazyLoadComponent } = pkg

// We have a copy of this Component in shared/components/src/v2/lib as well which
// supports data fetched from apollo instead of gatsby data layer. Functiality and
// structure is exactly same in both the components, so if you are making any changes
// to this, please make corresponding changes to the v2 version of this component too.
// We will make v2/ version the main version going forward when we are completely swapped
// over to apollo from gatsby data layer.
export const GuidedSystemBuilder = React.memo(
  ({ type, jebbitUrl }: GuidedSystemBuilderSchema) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const vid = usePollVid()
    const [clientId, sessionId] = useGoogleAnalytics()

    const url =
      jebbitUrl ||
      'https://quiz.simplisafe.com/kaedkhe4?L=Full+Page&uid=&deferred=true'

    function renderGSB() {
      return (
        <div ref={containerRef}>
          <LazyLoadComponent>
            <iframe
              allowFullScreen
              className={classNames(
                'jebbit-iframe min-h-[610px] w-full lg:h-fit'
              )}
              frameBorder="0"
              onLoad={() => embedJebbit('.jebbit-iframe')}
              seamless={true}
              src={`${url}&${createGSBLaunchUrlParams(
                vid,
                clientId,
                sessionId,
                type
              )}`}
              title="Guided System Builder Quiz"
            />
          </LazyLoadComponent>
        </div>
      )
    }

    return type === 'embedded' ? (
      <div
        className={classNames(
          'col-span-12 overflow-hidden rounded-2xl bg-white px-4'
        )}
      >
        {renderGSB()}
      </div>
    ) : (
      renderGSB()
    )
  }
)
