import { pipe } from 'fp-ts/lib/function'
import { Map as ImmutableMap } from 'immutable'
import * as O from 'fp-ts/lib/Option'
import { jsonSchema } from './lib/jsonSchema'
import { isObject } from '@simplisafe/ewok'
import { devError } from '@ecomm/error-handling'

type StaticQueryKey = string

/**
 * Parses `contentfulJson` into an Immutable Map.
 *
 * Use the Contentful `json` content type for text on pages that change frequently,
 * during an A/B test, or for things that aren't currently represented in the content model.
 *
 * It's good practice to create one `json` entry for each component to make it easy to track the entry and to avoid accidentally changing something you didn't mean to.
 *
 * The `json` content type can be localized and can be configured for variations for A/B testing if needed.
 *
 * @example
 * import { useStaticQuery, graphql } from 'gatsby'
 * import { parseContentfulJson } from '@ecomm/micro-copy'
 *
 * export function Component() {
 *  const data = useStaticQuery(graphql`
 *    query expertSection {
 *      json1: contentfulJson(contentful_id: { eq: "JvmAoMV5dWP4ILUxSFfJV" }) {
 *       ...json
 *      }
 *    }
 *    fragment json on ContentfulJson {
 *      contentful_id
 *      content {
 *        internal {
 *          content
 *        }
 *      }
 *    }
 *  `)
 *
 *  const copy = parseContentfulJson(data, 'json1')
 *
 *  return <h1>{copy.get('title')}</h1>
 * }
 *
 * @example
 * // how to mock in a unit test
 *
 * vi.mock('@ecomm/micro-copy')
 *
 * // defaults to returning an empty Map
 * vi.mocked(parseContentfulJson).mockImplementation(() => Map({}))
 *
 * // you can override this in your test
 * vi.mocked(parseContentfulJson).mockImplementation(() => Map({ "text_1": "Shop Now" }))
 */
export function parseContentfulJson(
  t: unknown,
  key: StaticQueryKey
): ImmutableMap<string, string> {
  devError(
    'parseContentfulJson is currently not typesafe, proceed with caution!'
  )
  return pipe(
    O.fromNullable(t),
    O.chain(t1 => (isObject(t1) ? O.fromNullable(t1[key]) : O.none)),
    O.map(jsonSchema.parse),
    O.map(t1 => t1.content.internal.content),
    O.map(JSON.parse),
    O.match(
      () => ImmutableMap(),
      // @ts-expect-error - TODO: ECP-12322 this type is unknown and the data needs to be parsed
      t2 => ImmutableMap(t2)
    )
  )
}
