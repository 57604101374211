import { type TypeOf, z } from '@simplisafe/ewok'

import {
  guidedSystemBuilderParsedSchema,
  guidedSystemBuilderSchema
} from '../../v2/lib/GuidedSystemBuilder/schema'

export const quoteWizardRawSchema = z.object({
  quoteWizard: guidedSystemBuilderSchema
})

export const quoteWizardSchema = z.object({
  quoteWizard: guidedSystemBuilderParsedSchema,
  label: z.string().optional()
})

export type QuoteWizardType = TypeOf<typeof quoteWizardSchema>
