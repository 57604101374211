import { trackNavigationClick } from '@ecomm/cdp-tracking'
import { Hamburger } from '@ecomm/shared-icons'
import React from 'react'
import { useTracking } from 'react-tracking'

import type { HeaderType } from './schema'

/*
We have a copy of this Component in shared/components/src/v2/lib as well which
supports data fetched from apollo instead of gatsby data layer. Functionality and
structure is exactly same in both the components, so if you are making any changes
to this, please make corresponding changes to the v2 version of this component too.
We will make v2/ version the main version going forward when we are completely swapped
over to apollo from gatsby data layer.
*/

type HamburgerButtonProps = {
  readonly type: HeaderType
  readonly showAside: boolean
  readonly toggleAside: () => void
}

export function HamburgerButton({
  type,
  showAside,
  toggleAside
}: HamburgerButtonProps) {
  const { trackEvent } = useTracking()

  // Hamburger button is also visible to PLA and Lander pages because there isn't room for the Shop Now button on mobile
  return type === 'full' || type === 'pla' || type === 'lander' ? (
    <button
      aria-expanded={showAside}
      aria-label="Open aside menu"
      className="cursor-pointer border-none bg-transparent px-1 py-0 text-white lg:hidden"
      onClick={() => {
        trackEvent({
          label: 'hamburger',
          event: 'navigation-link',
          eventAction: 'open',
          eventCategory: 'navigation'
        })
        trackNavigationClick({
          action: 'open',
          navElement: 'hamburger'
        })
        toggleAside()
      }}
    >
      <Hamburger className="w-4 md:w-5" />
    </button>
  ) : null
}
