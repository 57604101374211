import type { PromoImageSchema } from '@ecomm/contentful-schemas'
import { ContentfulImage } from '@ecomm/contentful/components'
import React from 'react'

type HeroPromoStickerProps = {
  readonly image: PromoImageSchema
}

export function HeroPromoSticker({ image }: HeroPromoStickerProps) {
  return (
    <div
      className="absolute bottom-[40px] xl:right-[-25px] lg:right-0 hidden max-w-[323px] items-center md:hidden lg:flex w-full"
      data-component="HeroPromoSticker"
    >
      <ContentfulImage
        classNameOverride="bg-transparent w-full object-contain"
        description={image.description}
        height={600}
        originalHeight={image.file?.details.image.height || 1}
        originalWidth={image.file?.details.image.width || 1}
        quality={90}
        url={image.file?.url || ''}
        width={600}
      />
    </div>
  )
}
