import { contentfulImageSchema } from '@ecomm/contentful/components'
import { type TypeOf, z } from '@simplisafe/ewok'

export const imageWithArtDirectionSchema = z.object({
  desktopImage: contentfulImageSchema,
  tabletImage: contentfulImageSchema,
  mobileImage: contentfulImageSchema
})

export type ImageWithArtDirectionSchema = TypeOf<
  typeof imageWithArtDirectionSchema
>
