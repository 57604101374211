import { trackClickBuildASystemCta } from '@ecomm/cdp-tracking'
import {
  type GatsbyImageSchema,
  GatsbyImage,
  type V2GuidedSystemBuilderSchema as GuidedSystemBuilderSchema,
  V2QuoteWizardModal as QuoteWizardModal
} from '@ecomm/shared-components'
import { Link } from '@ecomm/framework'
import React, { useState } from 'react'

export type DeadEndProps = {
  readonly image: GatsbyImageSchema
  readonly headline: string
  readonly body: string
  readonly button1: Omit<buttonProps, 'href'>
  readonly button2: buttonProps
  readonly quoteWizard?: GuidedSystemBuilderSchema
}

type buttonProps = {
  readonly text: string
  readonly href: string
}

export function DeadEnd({ data }: { readonly data: DeadEndProps }) {
  const { image, headline, body, button1, button2, quoteWizard } = data

  const [showModal, setShowModal] = useState<boolean>(false)

  return (
    <div
      className="md:align-stretch prose md:prose-md lg:prose-lg flex w-full flex-col md:flex-row"
      data-component="DeadEnd"
    >
      <div className="rounded-t-base h-auto w-full md:w-1/2">
        <GatsbyImage
          className="rounded-tl-base rounded-tr-base md:rounded-bl-base md:rounded-tl-base h-full w-full object-contain object-center md:rounded-tr-none"
          image={image}
        />
      </div>
      <div className="md:flex-start md:align-start rounded-b-base bg-neutral-black md:rounded-e-base flex h-1/2 flex-col p-6 md:h-auto md:w-1/2 md:justify-center md:rounded-bl-none md:p-12">
        <h2 className="text-heading-3-size leading-h3-height mb-6 text-white">
          {headline}
        </h2>
        <p className="mb-8 text-white">{body}</p>
        <button
          className="rounded-base bg-btn-primary text-body-size hover:bg-btn-dark mb-4 w-full cursor-pointer border-none px-4 py-3 leading-normal text-white transition-colors duration-200 md:w-64"
          onClick={() => setShowModal(true)}
        >
          {button1.text}
        </button>
        {/* This component is hard coded so hard coding the Url and type here */}
        {quoteWizard ? (
          <QuoteWizardModal
            data={{
              ...quoteWizard,
              sys: { id: '3kAryRQId8HOp5mD3AGhAw' },
              id: '3kAryRQId8HOp5mD3AGhAw',
              type: 'monitoring_component'
            }}
            onRequestClose={() => setShowModal(false)}
            show={showModal}
          />
        ) : null}
        <Link
          className="rounded-base hover:text-neutral-black w-full cursor-pointer border border-solid border-white bg-transparent px-4 py-3 text-center text-white no-underline transition-colors duration-200 hover:bg-white md:w-64"
          onClick={trackClickBuildASystemCta}
          to={button2.href}
        >
          {button2.text}
        </Link>
      </div>
    </div>
  )
}
