import type { QuoteWizardType } from './quoteWizardSchema'

export const quoteWizardMockData: QuoteWizardType = {
  quoteWizard: {
    __typename: 'QuoteWizard',
    id: '6wRdF53o74w2MhOhBIkzip',
    jebbitUrl: 'https://www.example.com',
    type: 'nav',
    ntExperiencesCollection: { items: [] },
    sys: { id: '6wRdF53o74w2MhOhBIkzip' }
  }
}
