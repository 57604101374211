import { none } from 'fp-ts/lib/Option'
import React from 'react'

import NavDropdown from './NavDropdown'
import NavLink from './NavLink'
import type { MainMenuProps, NavLinkProps, SimpleMenuProps } from './schema'

const isMenu = (el: NavLinkProps | SimpleMenuProps): el is SimpleMenuProps =>
  el.__typename === 'Menu'

export default function MobileNavMenu({
  menu
}: {
  readonly menu: MainMenuProps
}) {
  const { links } = menu

  return (
    <nav
      aria-label="Links box"
      className="flex flex-col gap-4 justify-self-start"
    >
      {links.items.map((element, index) =>
        !isMenu(element) ? (
          <NavLink
            className="text-neutral-black hover:text-complementary-blue-100"
            key={`Mobile-nav-menu-${index}`}
            {...element}
            trackingLabel={none}
          />
        ) : (
          <NavDropdown
            key={`Mobile-nav-menu-${index}`}
            {...element}
            hideLinkIcons
          />
        )
      )}
    </nav>
  )
}
