import {
  get as sessionStorageGet,
  set as sessionStorageSet
} from '@ecomm/utils'
import { useNinetailedFeatureFlag } from '..'
import { useEffect } from 'react'

const SS_KEY = 'phoneExperiment'

export const usePhoneAndMonitoringInNavExperience = () => {
  const { isVariant, status } = useNinetailedFeatureFlag({
    baselineId: '2iInxRQD3Te1nirvWGRZCr'
  })

  useEffect(() => {
    status === 'success' && sessionStorageSet(SS_KEY, String(isVariant))
  }, [isVariant, status])

  const isVariantSS = status !== 'success' ? sessionStorageGet(SS_KEY) : ''

  return status === 'success'
    ? {
        isVariant,
        status
      }
    : {
        isVariant: isVariantSS === 'true',
        status: !!isVariantSS ? <const>'success' : status
      }
}
