import {
  type ContentfulImageSchema,
  ContentfulImage,
  contentfulImageSchema
} from '@ecomm/contentful/components'
import classNames from 'classnames'
import { useMemo } from 'react'
import React from 'react'

export type ReviewProps = {
  readonly image?: ContentfulImageSchema
  readonly text: string
  readonly id: number
  readonly disclaimer?: string
}

const LONG_TEXT_LENGTH = 60

export default function Review(review: ReviewProps) {
  /* istanbul ignore next */
  const reviewImage = useMemo(
    () => (review.image ? contentfulImageSchema.parse(review.image) : null),
    [review.image]
  )

  /* istanbul ignore next */
  const isLongText = review.text.length > LONG_TEXT_LENGTH
  const disclaimer = review.disclaimer

  const reviewTextClasses = classNames(
    'mb-8 lg:mb-0 text-center leading-tight text-white lg:mr-4 lg:text-left',
    {
      'text-2xl': isLongText,
      'text-3xl': !isLongText
    }
  )

  return (
    <div
      className="bg-neutral-black flex w-full shrink-0 flex-grow snap-center flex-col items-center justify-center rounded-2xl md:flex-1"
      data-component="review"
    >
      <div className="flex flex-1 flex-col items-center justify-between p-8 lg:flex-row">
        <p className={reviewTextClasses}>{review.text}</p>
        <div className="flex flex-col justify-center lg:h-full lg:max-w-[30%] lg:basis-full items-center">
          {reviewImage ? (
            <ContentfulImage
              {...reviewImage}
              classNameOverride="max-h-[220px] lg:max-h-[80%] lg:min-h-fit object-contain max-w-fit lg:w-full min-h-[200px]"
            />
          ) : null}
          {disclaimer ? (
            <div className="pt-5 text-center text-xs text-white md:pt-2">
              {disclaimer}
            </div>
          ) : null}
        </div>
      </div>
      <div className="bg-primary-100 h-5 w-full rounded-bl-2xl rounded-br-2xl"></div>
    </div>
  )
}
