import type { ExperienceConfiguration } from '@ninetailed/experience.js-shared'

import {
  type ExperienceLike,
  ExperienceMapper
} from '@ninetailed/experience.js-utils'
import { isObject } from '@simplisafe/ewok'

function isReference<T extends { id: string }>(
  a: T | unknown
): a is ExperienceConfiguration<T> {
  return isObject(a) && typeof a['id'] === 'string'
}

export function safeMapExperiences<
  V extends { id: string },
  U extends { id: string } = { id: string },
  T extends { nt_experiences?: ExperienceLike<U>[] } = {
    nt_experiences?: ExperienceLike<U>[]
  }
>(experiences: T): ExperienceConfiguration<V>[] {
  return (
    experiences.nt_experiences
      ?.map(t => ({ ...t, variants: t.variants ?? [] }))
      .filter(x => ExperienceMapper.isExperienceEntry(x))
      .map(x => ExperienceMapper.mapExperience(x))
      .filter(x => isReference<V>(x)) ?? []
  )
}
