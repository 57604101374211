import { COOKIE_AWIN, setCookie } from '@ecomm/shared-cookies'
import type { Locale } from '@ecomm/utils'
import { StringParam, useQueryParam } from 'use-query-params'
import { params } from '../queryParams'

/**
 * Reads the awc param from the url and sets it to a cookie if it exists.
 *
 * Locale is passed as a prop instead of useLocale because this hook is used
 * in WrapPageElement, and the EnvContext is not initialized yet.
 */
export const useSetAwinCookie = (locale: Locale) => {
  const [awin] = useQueryParam(params.awc, StringParam)

  locale === 'en-GB' &&
    awin &&
    setCookie(COOKIE_AWIN, awin, {
      path: '/',
      sameSite: 'strict',
      secure: true
    })
}
