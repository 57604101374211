import * as T from 'fp-ts/lib/Task'
import * as TE from 'fp-ts/lib/TaskEither'
import { pipe } from 'fp-ts/lib/function'
import { fetchApi } from './lib/fetchApi'

export type AwinDataLayer = {
  readonly channel?: string
  readonly currency?: string
  readonly amount: number
  readonly orderRef: string
  readonly clickSource: string
  readonly parts?: string
  readonly voucher?: string
}

export const awinRequest = (
  data: AwinDataLayer,
  handleError: (err: Error) => void,
  handleSuccess?: () => void
) => {
  return pipe(
    fetchApi({
      endpoint: '/proxy/v1/awin',
      method: 'POST',
      headers: {},
      body: JSON.stringify(data)
    }),
    TE.fold(
      err =>
        T.of(handleError(Error(`awin request failed: ${JSON.stringify(err)}`))),
      () => T.of(handleSuccess && handleSuccess())
    )
  )()
}
