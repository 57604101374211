import classNames from 'classnames'
import React from 'react'

import Rating from './Rating'

type Review = {
  readonly name: string
  readonly text: string
  readonly showStars?: boolean
  readonly type?: 'big' | 'small'
}

function Review({ name, text, type = 'small', showStars = true }: Review) {
  const isSmallType = type === 'small'
  const isBigType = type === 'big'
  const ratingClasses = classNames({
    'mr-1 h-6 w-6 lg:mr-2 lg:h-9 lg:w-9 text-primary-100': isBigType
  })
  const textClasses = classNames({
    'mt-3 mb-6 text-xs lg:mb-0 lg:text-lg': isSmallType,
    'm-0 mt-3 lg:mt-5 text-lg lg:text-2xl leading-tight text-white font-bold':
      isBigType
  })
  const nameClasses = classNames({
    'mb-0 text-xs text-neutral-dark lg:text-sm': isSmallType,
    'm-0 mt-4 text-left text-base text-white lg:mt-6 lg:text-lg': isBigType
  })

  return (
    <div data-component={`review-${type}`}>
      {showStars ? <Rating starClasses={ratingClasses} /> : null}
      <p className={textClasses}>{text}</p>
      {isBigType ? (
        <p className={nameClasses}>— {name}</p>
      ) : (
        <p className={nameClasses}>{name}</p>
      )}
    </div>
  )
}

export default React.memo(Review)

Review.whyDidYouRender = true
