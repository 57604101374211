export const COOKIE_ATAT_TOKEN = 'atat_v2'
export const COOKIE_CHAT_APP_ID = 'chat_app_id'
export const COOKIE_CHAT_OPENED = 'chat_opened'
export const COOKIE_DRUPAL_UID = 'DRUPAL_UID'
export const COOKIE_FCP_ORDER_ID = 'FCP_ORDER_ID'
export const COOKIE_LEAD_DATA = 'leadData'
export const COOKIE_MONITORING_PLAN = 'mp'
export const COOKIE_MONITORING_GIFT_ITEM = 'monitoringGiftItem'
export const COOKIE_NON_MONITORING_GIFT_ITEM = 'nonMonitoringGiftItem'
export const COOKIE_PREACTIVATION = 'ssOauthAccessToken'
export const COOKIE_PURCHASED_CART_ID = 'purchasedCartId'
export const COOKIE_BRAZE_PROMO_VIEWED = 'braze_promo_viewed'
export const COOKIE_BRAZE_SITE_VISITED = 'braze_site_visited_fired'
export const COOKIE_VID = 'vid'
export const COOKIE_PARTNERS_DATA = 'partners_data'
export const COOKIE_AUTH0 = 'auth0.is.authenticated'
export const COOKIE_USER_TOKEN = 'userToken'
export const COOKIE_EMAIL = 'email'
export const COOKIE_RETURN_TO_QW = 'ss_return_to_qw'
export const COOKIE_SHOWED_ODM_INTENT = 'showedODMIntent'
export const COOKIE_QUIZ_BREAK_INS = 'quizBreakIns'
export const COOKIE_QUIZ_PACKAGE_THEFT = 'quizPackageTheft'
export const COOKIE_AWIN = 'awin_awc'
export const COOKIE_FIRST_LEAD_SUBMISSION = 'firstLeadSubmission'

export type CookieNames =
  | typeof COOKIE_ATAT_TOKEN
  | typeof COOKIE_AUTH0
  | typeof COOKIE_AWIN
  | typeof COOKIE_CHAT_APP_ID
  | typeof COOKIE_CHAT_OPENED
  | typeof COOKIE_DRUPAL_UID
  | typeof COOKIE_EMAIL
  | typeof COOKIE_FCP_ORDER_ID
  | typeof COOKIE_LEAD_DATA
  | typeof COOKIE_MONITORING_GIFT_ITEM
  | typeof COOKIE_MONITORING_PLAN
  | typeof COOKIE_NON_MONITORING_GIFT_ITEM
  | typeof COOKIE_PARTNERS_DATA
  | typeof COOKIE_PREACTIVATION
  | typeof COOKIE_PURCHASED_CART_ID
  | typeof COOKIE_QUIZ_BREAK_INS
  | typeof COOKIE_QUIZ_PACKAGE_THEFT
  | typeof COOKIE_SHOWED_ODM_INTENT
  | typeof COOKIE_USER_TOKEN
  | typeof COOKIE_VID

export type Cookie = Record<CookieNames, string | undefined>
