import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES, type NodeData } from '@contentful/rich-text-types'
import { ContentfulImage } from '@ecomm/contentful/components'
import { useLocale } from '@ecomm/data-hooks'
import { useMicroCopy } from '@ecomm/micro-copy'
import classNames from 'classnames'
import { Link } from '@ecomm/framework'
import React from 'react'
import { useState } from 'react'

import { PartnerWithUsForm } from '../Forms/PartnerWithUsForm'
import type { FormQuerySchema } from '../Forms/PartnerWithUsFormSchema'
import { QuoteWizardModal } from '../QuoteWizardModal'
import PromoTag from './PromoTag'
import type {
  ButtonFragment,
  EmbeddedLinksSchema,
  GuidedSystemBuilderSchema,
  HeroBannerColumnSchema
} from './schema'
import YoutubeVideoModal from './YoutubeVideoModal'
import { match } from 'ts-pattern'
import { devThrowError } from '@ecomm/error-handling'
import { youtubeVideoSchema } from '../YoutubeVideo/schema'

type HeroColumn = HeroBannerColumnSchema & {
  readonly className?: string
  readonly extraContent?: React.ReactNode
}

const renderForm = (form: FormQuerySchema | null, render: boolean) => {
  return form && render ? (
    <div className="not-prose col-span-12 md:col-span-6 lg:col-span-4">
      <div className="w-100 relative z-[1] rounded border-r-2 bg-neutral-100 p-9">
        <PartnerWithUsForm
          button={form.button}
          formCollection={form.formCollection}
          location="hero"
        />
      </div>
    </div>
  ) : null
}

export default function HeroBannerColumn({
  className = '',
  contentVerticalAlignment,
  logo,
  description,
  descriptionMobile,
  image,
  imageMobile,
  hasPromoSticker = false,
  imageTablet,
  textAlignment,
  textColor,
  desktopImageAlignToScreenEdge,
  extraContent = null,
  form = null
}: HeroColumn) {
  const [showModal, setShowModal] = useState(false)
  const [quoteWizardData, setQuoteWizardData] =
    useState<GuidedSystemBuilderSchema>()
  const microCopy = useMicroCopy()
  const isUS = useLocale() === 'en-US'

  const renderOptions = (links: EmbeddedLinksSchema) => {
    const entries = new Map()

    links?.entries?.block?.forEach(
      link => link && entries.set(link.sys.id, link)
    )

    links?.entries?.inline?.forEach(
      link => link && entries.set(link.sys.id, link)
    )

    return {
      renderNode: {
        [BLOCKS.EMBEDDED_ENTRY]: (node: NodeData) => {
          const {
            data: {
              target: {
                sys: { id: targetId }
              }
            }
          } = node
          const entry = entries.get(targetId)
          const type = entry?.__typename

          return type
            ? match(type)
                .with('QuoteWizard', () => {
                  const wizard: GuidedSystemBuilderSchema = entry
                  return (
                    <button
                      className="btn btn-solid-primary mb-4 mt-4 block w-fit min-w-[150px] md:min-w-[190px] justify-center md:mt-6 md:text-lg"
                      data-testid={`btn-${
                        wizard.buttonText || microCopy['take-our-quiz-label']
                      }`}
                      onClick={() => {
                        setQuoteWizardData(wizard)
                        setShowModal(true)
                      }}
                      type="button"
                    >
                      <span className="leading-normal">
                        {wizard.buttonText || microCopy['take-our-quiz-label']}
                      </span>
                    </button>
                  )
                })
                .with('Button', () => {
                  const button: ButtonFragment = entry
                  return (
                    <Link
                      className={classNames(
                        'btn mt-4 !px-4 block w-fit min-w-[190px] !no-underline md:mt-6 md:min-w-[224px]',
                        {
                          'btn-outlined-secondary btn-outlined':
                            button.type === 'Secondary Outline',
                          'btn-solid-primary': button.type === 'Primary',
                          'mx-auto': textAlignment === 'center'
                        }
                      )}
                      data-testid={`btn-${button.buttonText}`}
                      to={button.url}
                    >
                      {button.buttonText}
                    </Link>
                  )
                })
                .otherwise((str: string) => {
                  // TODO: make this exhaustive
                  devThrowError(Error(`Unsupported component: ${str}`))
                  return null
                })
            : null
        },
        [BLOCKS.HEADING_5]: (_: NodeData, children: React.ReactNode) => {
          const childrenArray = React.Children.toArray(children)
          if (childrenArray.length === 0) {
            return <h5>{children}</h5>
          } else {
            const [firstChild] = childrenArray
            const titleParts =
              typeof firstChild === 'string'
                ? firstChild.split(/(®)/)
                : [firstChild]

            return (
              <div className="font-arizona text-base md:text-2xl -mt-6 font-normal md:mb-4 text-white">
                {titleParts.map((part, index) =>
                  part === '®' ? (
                    <sup className="text-[8px] md:text-sm" key={index}>
                      ®
                    </sup>
                  ) : (
                    part
                  )
                )}
              </div>
            )
          }
        },
        [BLOCKS.HEADING_6]: (_: NodeData, children: React.ReactNode) => (
          // Use BLOCKS.HEADING_6 for the "NEW" tag
          <div className="bg-primary-100 z-10 inline-block rounded-md py-1.5 md:py-0.5 px-1.5 md:px-2 mb-2 md:mb-4 w-fit text-xs md:text-lg font-bold">
            {children}
          </div>
        ),
        [INLINES.EMBEDDED_ENTRY]: (node: NodeData) => {
          const {
            data: {
              target: {
                sys: { id: targetId }
              }
            }
          } = node
          const entry = entries.get(targetId)
          const type = entry?.__typename
          return type
            ? match(type)
                .with('YoutubeVideo', () => {
                  const ytVideo = entry
                  return (
                    <YoutubeVideoModal {...youtubeVideoSchema.parse(ytVideo)} />
                  )
                })
                .otherwise((str: string) => {
                  devThrowError(Error(`Unsupported component: ${str}`))
                  return null
                })
            : null
        }
      }
    }
  }

  return (
    <>
      <div
        className={classNames(
          'prose-a:underline hover:prose-a:no-underline grid grid-cols-12',
          {
            'prose-headings:text-white prose-p:text-white prose-a:text-white':
              textColor === 'neutralWhite',
            'prose-headings:text-neutral-light-100 prose-p:text-neutral-light-100 prose-a:text-neutral-light-100':
              textColor === 'neutralLight100',
            'justify-center text-center': textAlignment === 'center',
            'justify-right text-right': textAlignment === 'right',
            'items-baseline': contentVerticalAlignment === 'baseline',
            'items-center': contentVerticalAlignment === 'center',
            'items-end': contentVerticalAlignment === 'end',
            'items-start': contentVerticalAlignment === 'start',
            'items-stretch': contentVerticalAlignment === 'stretch'
          },
          className
        )}
      >
        <div
          className={classNames('absolute h-full w-full z-0', {
            'left-0 top-0': !desktopImageAlignToScreenEdge,
            'lg:inset-y-0 lg:right-1/2 lg:-mr-[50vw]':
              desktopImageAlignToScreenEdge === 'right',
            'lg:inset-y-0 lg:left-1/2 lg:-ml-[50vw]':
              desktopImageAlignToScreenEdge === 'left'
          })}
          data-component="image-container"
        >
          {/**
           * Having three different images is really bad.
           
           */}
          {imageMobile ? (
            <div className="flex h-full md:hidden">
              <ContentfulImage
                {...imageMobile}
                // Unset background color so hero banner color shows before image loads
                classNameOverride="w-full"
                loading="high"
                preload={true}
                width={767}
              />
            </div>
          ) : null}
          {imageTablet ? (
            <div className="static hidden h-full md:flex lg:hidden">
              <ContentfulImage
                {...imageTablet}
                // Unset background color so hero banner color shows before image loads
                classNameOverride="w-full"
                loading="high"
                preload={true}
                width={1239}
              />
            </div>
          ) : null}
          {image ? (
            <div
              className={classNames('static hidden h-full lg:flex', {
                'md:block': !imageTablet,
                block: !imageMobile
              })}
            >
              <ContentfulImage
                {...image}
                // Unset background color so hero banner color shows before image loads
                classNameOverride="w-full"
                loading="high"
                preload={true}
                quality={90}
                width={1366}
              />
            </div>
          ) : null}
        </div>
        <div
          className={classNames('col-span-12 flex flex-col', {
            'md:col-span-6 lg:col-span-8': !!form,
            'lg:max-w-lg': !!form,
            'items-center': textAlignment === 'center'
          })}
        >
          {hasPromoSticker ? (
            <PromoTag
              className={classNames({
                'border-white': textColor === 'neutralWhite',
                'border-neutral-black': textColor === 'neutralBlack'
              })}
            />
          ) : null}
          {descriptionMobile ? (
            <div className="relative z-[1] md:hidden">
              {logo ? (
                <ContentfulImage
                  {...logo}
                  classNameOverride="mb-6 h-[40px] w-[100px]"
                  loading="normal"
                  preload={true}
                  quality={60}
                />
              ) : null}
              {documentToReactComponents(
                { ...descriptionMobile.json },
                { ...renderOptions(description.links) }
              )}
            </div>
          ) : null}
          <div
            className={classNames('relative z-[1] md:block', {
              block: !descriptionMobile,
              hidden: !!descriptionMobile
            })}
          >
            {logo ? (
              <ContentfulImage
                classNameOverride="mb-6 h-[40px] w-[100px]"
                loading="normal"
                preload={true}
                {...logo}
              />
            ) : null}
            {documentToReactComponents(
              { ...description.json },
              { ...renderOptions(description.links) }
            )}
            {quoteWizardData ? (
              <QuoteWizardModal
                data={quoteWizardData}
                onRequestClose={() => setShowModal(false)}
                show={showModal}
              />
            ) : null}
          </div>
        </div>
        {extraContent ? (
          <div className="relative hidden w-4/5 justify-end md:flex lg:ml-7">
            {extraContent}
          </div>
        ) : null}
        {renderForm(form, isUS)}
      </div>
      {extraContent ? (
        <div className="relative flex w-full justify-end md:hidden">
          {extraContent}
        </div>
      ) : null}
    </>
  )
}
