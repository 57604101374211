/**
 * If the format is `unset` we don't want to add fm to the query.
 */
export const makeFormat = (format: string) =>
  format !== 'unset' ? `&fm=${format}` : ''

/**
 * Ensures src is not set before image is added to the DOM. In some cases this appears to cause an extraneous
 * request for the fallback src image w/o regard for srcset. In Firefox this causes a NS_BINDING_ABORTED error although the image
 * still displays for the user. (https://github.com/facebook/react/issues/20682#issuecomment-1252673568)
 */
export const addSrc = (src: string) => (img: HTMLImageElement) => {
  img && img.setAttribute('src', src)
}
