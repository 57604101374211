import classNames from 'classnames'
import React from 'react'

import type { ImageWithArtDirectionSchema } from './schema'
import { ContentfulImage } from '@ecomm/contentful/components'

export type ImageWithArtDirectionProps = {
  readonly className?: string
  readonly data: ImageWithArtDirectionSchema
}

export function ImageWithArtDirection({
  data,
  className = ''
}: ImageWithArtDirectionProps) {
  const { desktopImage, mobileImage, tabletImage } = data

  return (
    <>
      <ContentfulImage
        {...desktopImage}
        className={classNames('h-full hidden lg:block', className)}
      />
      <ContentfulImage
        {...mobileImage}
        className={classNames('h-full md:hidden lg:hidden', className)}
      />
      <ContentfulImage
        {...tabletImage}
        className={classNames('h-full md:block lg:hidden hidden', className)}
      />
    </>
  )
}
