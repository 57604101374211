import { trackIdentify } from '@ecomm/cdp-tracking'
import { useEffect } from 'react'

import { visitorIdAtAt } from '../atat'

/**
 * A hook abstraction to identify user based on visitorId at the
 * start of the session.
 *
 * This hook should generally only be used on app-startup unless
 * special use-cases are required (albeit unlikely)
 *
 * NOTE: This function may not run as expected during local development
 * or when utilizing qa environments on new session visits(i.e. incognito) since the
 * `visitorIdAtAt()` function will return undefined and is not hooked into React
 * dependency lifecycles.
 * The atat_v2 cookie is assigned on the edge for our staging and production environment,
 * but this is not true for localhost and team-specific-qa-environments.
 */
export function useTrackingVisitorArrival() {
  const visitorId = visitorIdAtAt()

  useEffect(() => {
    const { search } = window.location
    const ttclid = new URLSearchParams(search).get('ttclid')
    const gclid = new URLSearchParams(search).get('gclid')
    visitorId &&
      trackIdentify(visitorId, {
        // spread prevents overwriting ttclid when undefined
        ...(ttclid && { ttclid }),
        ...(gclid && { gclid })
      })
  }, [visitorId])
}
