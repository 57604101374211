import { Cancel } from '@ecomm/shared-icons'
import {
  brazeLogCustomEvent,
  useOptimizelyTrackSiteEvents,
  useTrackGuidedSystemBuilderOpenEvent
} from '@ecomm/tracking'
import classNames from 'classnames'
import React, { type ReactNode, useRef, useState } from 'react'
import Sticky from 'react-stickynode'
/*
We have a copy of this Component in shared/components/src/v2/lib as well which
supports data fetched from apollo instead of gatsby data layer. Functionality and
structure is exactly same in both the components, so if you are making any changes
to this, please make corresponding changes to the v2 version of this component too.
We will make v2/ version the main version going forward when we are completely swapped
over to apollo from gatsby data layer.
*/

type StickyPopoverLinkProps = {
  readonly children: ReactNode
  /** CSS class name for link */
  readonly linkClass?: string
  /** Text for link that triggers popover */
  readonly linkText: string
  /** data-component attribute value */
  readonly dataComponent?: string
}

export function StickyPopoverLink({
  children,
  linkClass = '',
  linkText,
  dataComponent = StickyPopoverLink.name
}: StickyPopoverLinkProps) {
  const trackGSBStart = useTrackGuidedSystemBuilderOpenEvent(
    'floating',
    linkText
  )
  const [isPopoverVisible, showPopover] = useState(false)
  const linkElement = useRef<HTMLButtonElement>(null)
  const linkClasses = classNames(
    'text-base',
    'cursor-pointer inline-block w-full underline normal-case whitespace-nowrap p-0 md:p-3 m-0 border-0 bg-transparent text-inherit hover:no-underline',
    { invisible: isPopoverVisible },
    linkClass
  )
  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()

  const closePopover = () => {
    showPopover(false)
  }

  const openPopover = () => {
    trackGSBStart()
    optimizelyTrackSiteEvents({ eventKey: 'get_a_quote_click_fs' })
    brazeLogCustomEvent('qw_start')
    showPopover(true)
  }

  return (
    <>
      <div data-component={dataComponent}>
        <div
          className="text-white"
          style={{ width: linkText.length * 8.5 + 16 }}
        >
          <Sticky
            enableTransforms={false}
            enabled={true}
            /*  Need to add max-lg:!relative class to avoid StickyLink in smaller resolutions and just keep it at top*/
            innerActiveClass="max-lg:!relative lg:bg-neutral-light-50 lg:text-neutral-black lg:text-right lg:translate-y-36 lg:z-[1] lg:rounded-base lg:shadow-[-3px_3px_7px_0_rgb(0,0,0,0.2)] lg:right-8"
          >
            <button
              className={linkClasses}
              onClick={openPopover}
              ref={linkElement}
            >
              {linkText}
            </button>
          </Sticky>
        </div>
      </div>
      <div
        className={classNames(
          'rounded-base bg-neutral-light-100 flex min-h-[396px] w-full max-w-sm items-center justify-center overflow-hidden bg-white p-6 shadow-[-3px_4px_10px_0_rgb(0,0,0,0.15)] md:max-w-[640px]',
          { hidden: !isPopoverVisible },
          'fixed right-[0px] top-32 z-[12] md:right-8'
        )}
      >
        <button
          aria-label="Close Popover"
          className="text-neutral-medium-120 absolute right-4 top-4 z-[1] h-6 w-6 cursor-pointer border-0 bg-transparent p-0"
          onClick={closePopover}
        >
          <Cancel
            className={classNames('h-6 w-6 opacity-70 hover:opacity-100')}
            titleA11y="Close"
          />
        </button>
        {isPopoverVisible ? children : null}
      </div>
    </>
  )
}
